import axios from "axios";
import { setInterceptors } from './common/interceptors';

/*
//interceptors 무관. 로그인,로그아웃,세션체크 등
const createAxiosService = () => {
  return axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL, //'http://localhost:9080',
  });
};

const createAxiosServiceWithAuth = () => {
  const axiosService = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
  });

  return setInterceptors(axiosService);
  
}; */

/* interceptors 거쳐가는 axios
const createAxiosServiceWithAuth2 = (url) => {
  const axiosService = axios.create({
    withCredentials: true,
    baseURL: `${process.env.VUE_APP_API_URL}/${url}`,
  });

  return setInterceptors(axiosService);
}; */

//---------------------------- fetchConfig 적용할경우

let cachedBaseURL = null;
let cachedBaseWs = null;

//만약, 사이트에 도메인 설정이 되어 있으면, 아래 설정 안해도 api 호출 잘 될것으로 보임.
const fetchConfig = async() => {
  if (cachedBaseURL) {
    return cachedBaseURL;
  }

  try {
    const response = await axios.get('/base-config'); // 서버 API 엔드포인트
    cachedBaseURL =  response.data;
    cachedBaseWs = cachedBaseURL.replace('http', 'ws');
  } catch (error) {
    cachedBaseURL = process.env.VUE_APP_API_URL;
    cachedBaseWs = cachedBaseURL.replace('http', 'ws');
  }

  return cachedBaseURL;
}
//interceptors 무관. 로그인,로그아웃,세션체크 등
const createAxiosService = async() => {
  const baseURL = await fetchConfig();

  const axiosService = axios.create({
    withCredentials: true,
    baseURL,
  });

  return axiosService;
};

const createAxiosServiceWithAuth = async() => {
  const baseURL = await fetchConfig();

  const axiosService = axios.create({
    withCredentials: true,
    baseURL,
  });

  return setInterceptors(axiosService);
  
};
export {cachedBaseWs};

export const getAxiosService = async () => {
  const axiosService = await createAxiosService();
  return axiosService;
};

export const getAxiosAuth = async () => {
  const axiosService = await createAxiosServiceWithAuth();
  return axiosService;
};

export const axiosService = createAxiosService();
export const axiosWithAuth = createAxiosServiceWithAuth();
//export const machines = createAxiosServiceWithAuth2("api/v1/machine");