import { createWebHistory, createRouter } from 'vue-router';
//import { useCookies } from 'vue3-cookies';
import userStore from '@/store/modules/userStore.js';
import store from '@/store'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/components/LoginPage')
    },
    {
        path: '/',
        redirect: '/asset/status',
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/components/HomePage')
    },
    {
        path: '/dashboard/integrated',
        name: 'integratedDashboard',
        component: () => import('@/components/dashboard/IntegratedMain')
    },
    {
        path: '/dashboard/machine',
        name: 'machineDashboard',
        component: () => import('@/components/dashboard/MachineBoard')
    },
    {
        path: '/dashboard/software',
        name: 'softwareDashboard',
        component: () => import('@/components/dashboard/SoftwareDistribution')
    },
    {
        path: '/dashboard/management',
        name: 'dashboardManage',
        component: () => import('@/components/dashboard/DashboardManage')
    },
    {
        path: '/cash-flow/transaction',
        name: 'transaction',
        component: () => import('@/components/cashflow/TransInfo'),
    },
    {
        path: '/cash-flow/workload',
        name: 'workload',
        component: () => import('@/components/cashflow/WorkReport')
    },
    {
        path: '/cash-flow/collection',
        name: 'collection',
        component: () => import('@/components/cashflow/CollectionMain')
    },
    {
        path: '/asset/status',
        name: 'status',
        component: () => import('@/components/asset/StatusMain')
    },
    {
        path: '/asset/capacity',
        name: 'capacity',
        component: () => import('@/components/asset/CapacityMain')
    },
    {
        path: '/asset/group',
        name: 'group',
        component: () => import('@/components/asset/GroupMain')
    },
    {
        path: '/asset/error',
        name: 'error',
        component: () => import('@/components/asset/ErrorMain')
    },
    //구현안됨
    {
        path: '/asset/upgrade',
        name: 'upgrade',
        component: () => import('@/components/upgrade/MachineUpgrade')
    },
    {
        path: '/asset/package',
        name: 'package',
        component: () => import('@/components/upgrade/PackageMain')
    },
    //구현안됨
    {
        path: '/asset/log',
        name: 'downloadLog',
        component: () => import('@/components/upgrade/DownloadLog')
    },
    {
        path: '/asset/schedule',
        name: 'schedule',
        component: () => import('@/components/upgrade/ScheduleMain')
    },
    {
        path: '/manage/user',
        name: 'user',
        component: () => import('@/components/user/UserMain')
    },
    {
        path: '/manage/branch',
        name: 'branch',
        component: () => import('@/components/user/BranchMain')
    },
    {
        path: '/manage/location',
        name: 'location',
        component: () => import('@/components/user/LocationMain')
    },
    {
        path: '/*/test',
        component: () => import('@/components/NotFoundPage')
    },
    /*{
        path: '/hello',
        name: 'Hello',
        component: () => import('@/components/HelloWorld'), // 동적 import
    },
    {
        path: '/',
        redirect: '/posts',
        name: 'TheContainer',
        component: () => import('@/components/layout/TheContainer'),
        children: [
        {
            path: '/posts',
            name: 'Posts',
            component: () => import('@/components/posts/Posts'),
        },
        {
            path: '/posts/detail',
            name: 'PostsDetail',
            component: () => import('@/components/posts/PostsDetail'),
        },
        {
            path: '/posts/reg',
            name: 'PostsReg',
            component: () => import('@/components/posts/PostsReg')
        }
        ]
    }*/
];

const router = createRouter({
    history: createWebHistory("/cdms"),
    routes,
})

/* const checkSession = () => {    
    const { cookies } = useCookies()
    if(cookies.get('JSESSIONID') !== null)
        return true;
    else
        return false
} */

/* const check = async () => {
    try {
        await this.$store.dispatch('userStore/check');
        this.$router.push({name: 'Home'})

    }catch(error) {
        //TODO: 오류코드
        alert("오류: " + error)
    } 
} */
router.beforeEach(async (to) => {
    let isAuthenticated= userStore.state.isLogin

    if(to.name == 'integratedDashboard') {
        await store.dispatch('userStore/loginTrue')

        console.log(userStore.state.isLogin)
        return
    }

    if(isAuthenticated == null && to.name != 'Login') {
        await store.dispatch('userStore/check')

        if(!userStore.state.isLogin) {
            return {name: 'Login'}
        }
        
    } else if( !isAuthenticated && to.name != 'Login') {
        return {name: 'Login'}
    } else if ( isAuthenticated && to.name == 'Login') {
        return {name: 'status'}
    }

    //console.log('라우터에서 토큰체크 => 그대로', to)
})

export default router;