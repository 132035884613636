import { logoutUser, checkUser } from '@/service/auth'
//import { useCookies } from 'vue3-cookies'
//const { cookies } = useCookies()
//import router from '@/router'

const userStore = {
    namespaced: true,
    state: {
        //session: cookies.get('JSESSIONID') || null,
        userId: null,
        userName: null,
        role: null,
        isLogin: null, //false //(cookies.get('JSESSIONID') !== null),
    },
    getters: {
        //'session': state => state.session,
        'userId': state => state.userId,
        'userName': state => state.userName,
        'role': state => state.role,
        'isLogin': state => state.isLogin,
        //state값이 변경됐을때 특정 상태를 계산. computed와 비슷
        /* isLogin(state) {
            return state.token !== null;
        }, */
        isTestUser(state) {
            return state.role === 'TEST_USER';
        },
        getUserId(state) {
            return state.userId
        }
    },
    mutations: {
        loginTrue(state) {
            state.isLogin = true
        },
        login (state, item) {
            //state.token = item.headers['accesstoken']
            //state.session = cookies.get('JSESSIONID') //item['session']
            state.userId = item['userId']
            state.userName = item['displayName']
            state.role = item['role']
            state.isLogin = true
        },
        logout (state) {
            //state.session = null
            state.userId = null
            state.userName = null
            state.role = null
            state.isLogin = false
        },
    },
    actions: {
        loginTrue ({commit}) {
            commit('loginTrue')
        },
        login ({commit}, loginInfo) {            
            commit('login', loginInfo)
        },
        async logout ({commit, state}) {
            //const { cookies } = useCookies()
            //cookies.remove('JSESSIONID')
            await logoutUser(state.userId);
            commit('logout')
        },
        async reset ({commit}) {
            commit('logout')
        },
        async check ({commit}) {
            try {
                const response = await checkUser();
                const loginInfo = {
                    ...response.data.payload,
                }
                
                commit('login', loginInfo)
            } catch (res_1) {
                console.log('실패: ', res_1);
                commit('logout')
            }
        }
    }
}

export default userStore